import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CurrentClientService } from 'src/app/services/current.client.service';

@Component({
  selector: 'app-sidebar-initial',
  templateUrl: './sidebar-initial.component.html',
  styleUrls: ['./sidebar-initial.component.scss']
})
export class SidebarInitialComponent implements OnInit {

  constructor(
    public currentUserService: CurrentUserService,
    public currentClientService: CurrentClientService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.currentUserService.logout()
  }

}
