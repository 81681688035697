import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { idTokenResult } from '@angular/fire/compat/auth-guard';
import { Router } from '@angular/router';
import { combineLatest, forkJoin, map, Observable, zip } from 'rxjs';
import { SsoUser } from '../model/sso-user';
import { CurrentClientService } from './current.client.service';
import { UserAdminService } from './user-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

    isLoggedIn$: Observable<boolean>;
    isLoggedOut$: Observable<boolean>;
    user$: Observable<SsoUser>;
    isSsoAdmin$: Observable<boolean>;

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private userAdminService: UserAdminService,
        public currentClientService: CurrentClientService
        ){

            this.isLoggedIn$ = afAuth.authState
            .pipe(
                map(user => !!user)
            );

        this.isLoggedOut$ = this.isLoggedIn$
            .pipe(
                map(loggedIn => !loggedIn)
            );

        this.user$ = this.afAuth.idTokenResult
            .pipe(
                map(idToken =>{
                    // console.log(idToken);
                    const user = {
                        id: idToken?.claims['user_id'],
                        email: idToken?.claims['email'],
                        firstname: idToken?.claims['firstname'],
                        lastname: idToken?.claims['lastname'],
                        rolesByClientId: idToken?.claims['rolesByClientId'],
                        isSsoAdmin: idToken?.claims['isSsoAdmin'] ?? false
                    } as SsoUser

                    return idToken ? user : null;
                })
            );
        
        this.isSsoAdmin$ = this.user$
            .pipe(
                map(user => user && user.isSsoAdmin)
            );
    }

    async reLogin(redirect: boolean){
        const resp = await this.userAdminService.checkSession().toPromise();
        if (resp.data && resp.data.session) {
            const loginToken = resp.data.loginToken;
            await this.afAuth.signInWithCustomToken(loginToken);
            
            if(redirect) {
                await this.router.navigateByUrl(this.currentClientService.routePrefix + "/dispatch");
            }
        }
    }

    async refreshIdToken(force: boolean = true) {
        const user = await this.afAuth.currentUser;
        await user.getIdTokenResult(force);
    }

    async logout(){
        await this.userAdminService.deleteSessionCookie().toPromise();
        await this.afAuth.signOut();
        await this.router.navigateByUrl(this.currentClientService.routePrefix + "/login");
    }
}
