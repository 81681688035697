import { Component, OnInit } from '@angular/core';
import { CurrentClientService } from 'src/app/services/current.client.service';

@Component({
  selector: 'app-header-second',
  templateUrl: './header-second.component.html',
  styleUrls: ['./header-second.component.scss']
})
export class HeaderSecondComponent implements OnInit {

  constructor(
    public currentClientService: CurrentClientService
  ) { }

  ngOnInit(): void {
  }

}
