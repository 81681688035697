import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { take } from "rxjs";

@Injectable({
   providedIn: 'root' 
})
export class AuthTokenService { 
    constructor(
        private afAuth: AngularFireAuth
    ) {}

    async getJwt():Promise<string | null | undefined> {
        const jwt = await this.afAuth.idToken
        .pipe(
            take(1)
        )
        .toPromise();
        
        return jwt;
    }
}