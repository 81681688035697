<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
    <div class="login-loader">
        <div class="loader-inner">
            <div class="loader-image">
                <img src="assets/images/carrot-logo-black-cropped.png" alt="">
            </div>
            <div class="loader-progress"></div>
        </div>
    </div>
    <div class="login-widget" *ngIf="showLogin" [ngClass]="{'loading': loaderShown}">
        <div class="login-widget-content">
            <div class="login-widget-branding">
                <img src="/assets/images/carrot-logo.png" alt="SSO logo">
                <div class="loader">
                    <div class="loader-inner"></div>
                </div>
            </div>
            <div class="login-form-wrap">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <h1 class="title">Login</h1>
                    <div class="login-failed" *ngIf="showErrorMessage">
                        <p>{{ errorMessage }}</p>
                    </div>
                    <mat-form-field class="mat-form-field-custom" [color]="email?.valid && 'accent'">
                        <input
                            matInput
                            formControlName="email"
                            type="email"
                            placeholder="Email"
                            autocomplete="off"
                        />
                    </mat-form-field>
                    <mat-form-field class="mat-form-field-custom" [color]="password?.valid && 'accent'" >
                        <input
                            matInput
                            formControlName="password"
                            [type]="showPassword ? 'text' : 'password'"
                            placeholder="Password"
                            autocomplete="off"
                        />
                        <button
                            class="button-unmask"
                            type="button"
                            [ngClass]="showPassword ? 'hide-password' : 'show-password'"
                            (click)="toggleShowPassword()"
                        >
                            <span class="unmask">
                                <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="SSO" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="SSO---Login" transform="translate(-834.000000, -407.000000)" fill="#AFB3C1" fill-rule="nonzero">
                                            <g id="Text-Fields" transform="translate(576.000000, 342.000000)">
                                                <g id="Password-Icon" transform="translate(258.000000, 65.400000)">
                                                    <path d="M8,1.49333333 C10.7563636,1.49333333 13.2145455,3.08373333 14.4145455,5.6 C13.2145455,8.11626667 10.7563636,9.70666667 8,9.70666667 C5.24363636,9.70666667 2.78545455,8.11626667 1.58545455,5.6 C2.78545455,3.08373333 5.24363636,1.49333333 8,1.49333333 M8,0 C4.36363636,0 1.25818182,2.32213333 0,5.6 C1.25818182,8.87786667 4.36363636,11.2 8,11.2 C11.6363636,11.2 14.7418182,8.87786667 16,5.6 C14.7418182,2.32213333 11.6363636,0 8,0 Z M8,3.73333333 C9.00363636,3.73333333 9.81818182,4.5696 9.81818182,5.6 C9.81818182,6.6304 9.00363636,7.46666667 8,7.46666667 C6.99636364,7.46666667 6.18181818,6.6304 6.18181818,5.6 C6.18181818,4.5696 6.99636364,3.73333333 8,3.73333333 M8,2.24 C6.19636364,2.24 4.72727273,3.74826667 4.72727273,5.6 C4.72727273,7.45173333 6.19636364,8.96 8,8.96 C9.80363636,8.96 11.2727273,7.45173333 11.2727273,5.6 C11.2727273,3.74826667 9.80363636,2.24 8,2.24 Z" id="Shape"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </mat-form-field>
                    <div class="extra-buttons-wrap">
                        <a routerLink="{{ currentClientService.routePrefix }}/forgot-password" class="button-forgotten-pass">Forgot password?</a>
                    </div>
                    <div class="form-buttons-wrap">
                        <button
                            color="=accent"
                            type="submit"
                            class="button-login"
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <footer class="login-page-footer" *ngIf="showLogin">
        <div class="logo">
            <a href="https://carrot.net" target="_blank">
                <img src="/assets/images/carrot-logo.png" alt="Carrot.net logo">
            </a>
        </div>
        <div class="links">
            <a href="mailto:accounts@carrot.net">Need help?</a>
        </div>
    </footer>
</div>

<!-- <button class="google-login" mat-raised-button (click)="loginWithGoogle()">
    <img src="/assets/google-logo.svg"/> Login with Google
</button> -->
