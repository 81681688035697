import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputModule } from '../input/input.module';
// import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { JSONEditModalComponent } from './json-edit-modal/json-edit-modal.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    InputModule,
    NgJsonEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    // DynamicDialogModule
  ],
  declarations: [
    BasicModalComponent,
    JSONEditModalComponent
  ],
  exports: [
    BasicModalComponent,
    JSONEditModalComponent
    // DynamicDialogModule
  ],
  // providers: [DialogService]
})
export class ModalModule { }
