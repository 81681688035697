<div class="header">
  <h1 class="title">{{ data.title }}</h1>
</div>
<div class="content">
  <div class="dialog-message" [innerHTML]="data.message"></div>
  <div class="input-wrap input-confirm" *ngIf="data.withInputConfirm">
    <mat-form-field class="mat-form-field-custom">
      <input
        matInput
        [formControl]="inputControl"
        type="text"
        placeholder="Type in carrot"
        autocomplete="off"
        required
      />
      <mat-error>
        <span class="error-message">Type in 'carrot' to confirm.</span>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="changes" *ngIf="data.changes && data.changes.length > 0">
    <table>
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Old Value</th>
          <th>New Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let change of data.changes">
          <td>{{ change.property }}</td>
          <td>{{ change.oldValue }}</td>
          <td>{{ change.newValue }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="actions">
  <button *ngIf="data.cancelText" class="button button-secondary" (click)="cancel()">{{ data.cancelText }}</button>
  <button *ngIf="data.confirmText" class="button button-primary" (click)="confirm()">{{ data.confirmText }}</button>
</div>