import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NotificationDialogService } from '../services/notification-dialog.service';
// import { StorageService } from '../services/storage.service';
// import { Tokens } from '../model/auth.model';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {
  requestCount = 0;
  noLoaderRoutes = [
    'loader=false',
    '/api/v1/profile/names',
    '/api/v1/profile/password',
    '/api/v1/profile/email',
    '/api/v1/admin/createAuthUser',
    '/api/v1/admin/setPassword'
  ];

  noErrorRoutes = [
    'config/client',
    '/api/v1/profile/verifyEmail'
  ]

  constructor(
    private router: Router,
    // private storage: StorageService,
    private loaderService: LoaderService,
    private notificationDialogService: NotificationDialogService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.requestCount++;

    if (this.noLoaderRoutes.filter(str => req.url.includes(str)).length === 0) {
      this.loaderService.showLoader();
    }

    // req = req.clone({
    //   headers: req.headers.set('Authorization', `Bearer ${this.storage.retrieve(Tokens.ACCESS)}`),
    // });


    return next.handle(req).pipe(
      tap((response: any) => {
        // this will be executed for all successfull requests that have body object - our own responses have this
        if (response?.body) {

          // getting the body of successfull response - needed, because we throw errors in it
          const responseBody = response.body;

          // checking if response has succeeded - this will be true for all successfull calls and false for calls that return OUR OWN error
          if (responseBody.success === false) {

            // why are the errors an array? TBD with @Bart
            // getting errors from the response body and assigning them to different vars for easier understanding
            const errors = responseBody.errors[0];
            const errorClientMessage = errors.clientMesssage;
            const errorDevMessage = errors.error;
            const errorCode = errors.rpErrorCode;
            const responseURL = response.url;

            // composing the final client message
            const finalErrorMessage = errorClientMessage ? errorClientMessage : errorDevMessage;

            // constructing error dialog content
            const errorDialogContent = {
              title: `An error occurred on ${responseURL}}`,
              message: `<p>Error ${errorCode}: ${finalErrorMessage}</p>`,
              confirmText: 'Ok'
            }

            // calling notification service and passing the needed information
            // TODO - to be updated with the correct error statements
            this.notificationDialogService.open(errorDialogContent);

            this.notificationDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                //do something if confirmed is true
              } else {
              }
            });
          };
        };
      }),
      catchError((err) => {
        if (this.noErrorRoutes.filter(str => req.url.includes(str)).length === 0) {
          // this is mainly done for error 404103 currently
          // this.loaderService.showLoader();

          // this will be executed for all responses that didn't pass for some reason
          // this will return HttpsErrorResponse

          // compiling the error to be shown
          let errorTitle = '';
          let APIErrorMessage = '';
          let errorConfirmButton = 'Ok';

          let errorsToBeDisplayed = [];

          if (err.error.errors) {
            let APIErrorsArray = err.error.errors;
            APIErrorsArray.forEach((element: any) => {
              switch (element.errorCode) {
                case 400100:
                  errorTitle = 'This email address is not available';
                  APIErrorMessage = element.message;
                break;
                case 400130:
                  errorTitle = 'Email already verified';
                  APIErrorMessage = element.message;
                break;
                case 400150:
                  errorTitle = 'Password not changed';
                  APIErrorMessage = element.message;
                break;
                default:
                  let tempMessage = '';
                  if (element.clientMessage && element.message) {
                    tempMessage = `${element.clientMessage} ${element.message}`;
                  } else if (element.clientMessage) {
                    tempMessage = `${element.clientMessage}`;
                  } else if (element.message) {
                    tempMessage = `${element.message}`;
                  }
                  errorTitle = 'An error occurred';
                  APIErrorMessage = `Error ${element.errorCode}: ${tempMessage}`
                break;
              };

              // constructing error dialog content
              errorsToBeDisplayed.push({
                title: errorTitle,
                message: `<p>${APIErrorMessage}</p>`,
                confirmText: errorConfirmButton
              })
            });
          } else {
            // not sure if this is used/usable
            // error array not present in response
            if (err.error.title) {
              errorTitle = err.error.title
              APIErrorMessage = errorTitle;
            };

            let traceId;
            if (err.error.traceId) {
              traceId = err.error.traceId;
              APIErrorMessage = `Trace ID: ${traceId}`;
            };

            if (errorTitle && traceId) {
              APIErrorMessage = `${errorTitle}. Trace ID: ${traceId}`;
            };

            // constructing error dialog content
            errorsToBeDisplayed.push({
              title: 'An error occurred',
              message: `<p>${APIErrorMessage}</p>`,
              confirmText: errorConfirmButton
            })
          };

          // opening error modal for each error received in the errors array.
          errorsToBeDisplayed.reverse().forEach(element => {
            this.notificationDialogService.open(element);

            this.notificationDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                // do something if confirmed is true
              } else {
                // do something if confirmed is false
              }
            });
          });
          return throwError(() => err);
        } else {
          return throwError(() => err);
        }
      }),
      finalize(() => {
        // finalizing the requests
        this.requestCount--;
        if (this.requestCount === 0) {
          this.loaderService.hideLoader();
        }
      })
    );
  }
}