import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { CurrentClientService } from 'src/app/services/current.client.service';
import { UserAdminService } from 'src/app/services/user-admin.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  errorMessage: string = '';
  showErrorMessage: boolean = false;
  showPassword: boolean = false;
  showLogin: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private router: Router,
    private userAdminService: UserAdminService,
    public currentClientService: CurrentClientService,
    private tabTitleService: TabTitleService
  ) { }

  async ngOnInit(): Promise<void> {
    this.tabTitleService.setTabTitle('Login');
    this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.userAdminService.checkSession()
    .subscribe(async resp => {
      if (resp.data && resp.data.session) {
        const loginToken = resp.data.loginToken;
        await this.afAuth.signInWithCustomToken(loginToken)
        .then(result => {
          if (result) {
            this.redirectUser();        
          }
          else {
            this.showLogin = true;
          }
        })
      }
      else {
        this.showLogin = true;
      }
    })

    this.form = this.fb.group({
      email: [''],
      password: [''],
    })
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  onSubmit() {
    if (!this.formSubmitting) {
      this.loaderShown = true;
      this.formSubmitting = true;

      const email = this.email?.value;
      const password = this.password?.value;
      try {
        this.showErrorMessage = false;
        this.afAuth.signInWithEmailAndPassword(email, password)
          .then(result => {
            if (result) {
              this.redirectUser();
            }
          }).catch((error) => {
            const errorCode = error.code;
            this.errorMessage = error.message;
            switch (errorCode) {
              case "auth/user-not-found":
              case "auth/invalid-email":
              case "auth/user-disabled":
              case "auth/wrong-password":
              case "auth/account-exists-with-different-credential":
                this.errorMessage = "Login failed: wrong email or password.";
                break;
              case "auth/too-many-requests":
                this.errorMessage = "Your account was locked after too many failed login attempts. Please try again later.";
                break;
              case "auth/internal-error":
              case "auth/invalid-user-token":
              default:
                  this.errorMessage = "Login failed: internal error."
            }
            this.showErrorMessage = true;
            this.loaderShown = false;
            this.formSubmitting = false;
          });
      } catch (error: any) {
        // this.errorMessage = error;
        console.log(error);

        this.loaderShown = false;
        this.formSubmitting = false;
        const errorCode = error.code;
        let errorMessage = error.message;
      }
    }
  }

  // loginWithGoogle() {
  //   this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
  //     .then(result => {
  //       if(result) {         
  //       }
  //     });
  // }

  async redirectUser() {
    await this.userAdminService.createSessionCookie(this.currentClientService.clientId).toPromise();
    if(this.currentClientService.initialRoute.endsWith("login") ||
      this.currentClientService.initialRoute.endsWith("login/")){
        this.currentClientService.clearInitialRoute();
      }
    this.router.navigateByUrl(this.currentClientService.routePrefix);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
