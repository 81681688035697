import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable, tap } from 'rxjs';
import { SsoApiResponse } from '../model/shared';
import { SsoClient, SsoClientPublicConfig, SsoClientsSettings } from '../model/sso-client';
import { convertSnap, convertSnaps, formatApiUrl } from './utilities';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
  ) { }

  getClientById(id: string): Observable<SsoClient> {
    return this.db.collection("clients")
      .doc<SsoClient>(id)
      .get()
      .pipe(
        map(snap => convertSnap<SsoClient>(snap) )
      )
  }

  getClients(filters: any):Observable<SsoClient[]> {
    const query =  this.db.collection('clients',
      ref => 
      
      {
        let query: any = ref;

         return query;
       }
    );

    return query.get().pipe(
      map(results => convertSnaps<SsoClient>(results))
    );
  }

  

  getClientPublicConfig(clientId: string) : Observable<SsoApiResponse<SsoClientPublicConfig>> {
    
    return this.http.get<SsoApiResponse<SsoClientPublicConfig>>(formatApiUrl('/api/v1/config/client/' + clientId));
  }
}
