<div class="login-page">
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img src="/assets/images/carrot-logo.png" alt="SSO logo">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>
      <div class="login-form-wrap">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="showForm">
          <h1 class="title">Forgot Password</h1>
          <p class="subtitle">Enter your email and we'll send you a link to reset your password.</p>
          <div class="forgot-pass-failed" *ngIf="showErrorMessage">
            <p>{{ errorMessage }}</p>
          </div>
          <mat-form-field class="mat-form-field-custom" [color]="email?.valid && 'accent'">
            <input
              matInput
              formControlName="email"
              type="email"
              placeholder="Email"
              autocomplete="off"
            />
            <mat-error *ngIf="email?.invalid && email?.dirty">
              <span class="error-message">You must enter a valid email address</span>
            </mat-error>
          </mat-form-field>
          <div class="form-buttons-wrap">
            <button
              color="=accent"
              type="submit"
              class="button-login"
              [disabled]="form.invalid"
            >
              Send Link
            </button>
          </div>
          <div class="extra-buttons-wrap">
            <span class="text">Remember password?</span>
            <a routerLink="{{ currentClientService.routePrefix }}/login" class="button-forgotten-pass">Back to Login</a>
          </div>
        </form>
        <div class="info-wrap" *ngIf="!showForm">
          <h1 class="title">Email Link Sent</h1>
          <p class="subtitle">An email has been sent to {{ email.value }} with instructions on resetting your password.</p>
          <div class="extra-buttons-wrap">
            <span class="text">Remember password?</span>
            <a routerLink="{{ currentClientService.routePrefix }}/login" class="button-forgotten-pass">Back to Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="login-page-footer">
    <div class="logo">
      <a href="https://carrot.net" target="_blank">
        <img src="/assets/images/carrot-logo.png" alt="Carrot.net logo">
      </a>
    </div>
    <div class="links">
      <a href="mailto:accounts@carrot.net">Need help?</a>
    </div>
  </footer>
</div>

<!-- <button class="google-login" mat-raised-button (click)="loginWithGoogle()">
  <img src="/assets/google-logo.svg"/> Login with Google
</button> -->
