
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';

export function convertSnaps<T>(results: firebase.firestore.QuerySnapshot<unknown>) : T[] {
    
    return <T[]>results.docs.map(snap =>convertSnap<T>(snap))
}

export function convertSnap<T>(snap: firebase.firestore.DocumentSnapshot<unknown>) : T {
    return {
        id: snap.id,
        ...<any>snap.data()
    }
}


export function formatApiUrl(relativePath:string) : string {
    return environment.apiBaseUrl + relativePath;
  }

export function convertDateTimeStringToDate(input: string) : Date {
    // 2022-11-28T15:00
    const dateTime = input.split("T");
    const date = dateTime[0].split("-")
    const year = parseInt(date[0]);
    const month = parseInt(date[1]);
    const day = parseInt(date[2]);

    const time = dateTime[1].split(":");
    const hour = parseInt(time[0]);
    const minute = parseInt(time[1]);

    const datetimeUtc = Date.UTC(year, month-1, day, hour, minute);
    const dateUtc = new Date(datetimeUtc);

    return dateUtc;
}

export function compareArrays(arr1: string[], arr2: string[]): boolean {
    if (!arr1 || !arr2) {
        return false;
    }

    return arr1.length === arr2.length && arr1.every((element, index) => element === arr2[index]);
}