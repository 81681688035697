import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SsoClientPublicConfig } from 'src/app/model/sso-client';
import { SsoUser } from 'src/app/model/sso-user';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CurrentClientService } from 'src/app/services/current.client.service';
import { ConfirmationDialogService } from 'src/app/shared/services/confirmation-dialog.service';
import { NotificationDialogService } from 'src/app/shared/services/notification-dialog.service';

@Component({
  selector: 'app-header-initial',
  templateUrl: './header-initial.component.html',
  styleUrls: ['./header-initial.component.scss']
})
export class HeaderInitialComponent implements OnInit {

  user: SsoUser;
  showProfileMenu: boolean = false;

  branding: SsoClientPublicConfig = {
    id: '',
    name: '',
    baseUrl: '',
    privacyPolicyURL: '',
    termsURL: '',
    faviconImageURL: '',
    logoImageURL: '',
    logoAltTag: '',
    emailSignatureURL: '',
    supportEmail: '',
    emailSender: '',
  };

  constructor(
    public currentUserService: CurrentUserService,
    public currentClientService: CurrentClientService
    ) { }

  ngOnInit(): void {
//    this.appId = this.route.snapshot.paramMap.get("appId")
    this.currentUserService.user$.subscribe(ssoUser => {
      this.user = ssoUser;
    });
    this.getBranding();
  }

  async getBranding() {
    this.branding = await lastValueFrom(this.currentClientService.publicClientConfig$);
  }

  logout(){
    this.currentUserService.logout()
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  closeProfileMenu() {
    this.showProfileMenu = false;
  }
}
