import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { PaginatedResult, SimpleMessage, SsoApiResponse } from '../model/shared';
import { UserApp } from '../model/sso-client';
import { SearchUserCriteria, SsoUser, UserProfile } from '../model/sso-user';
import { convertSnaps, convertSnap, formatApiUrl } from './utilities';
import firebase from 'firebase/compat/app';
import Typesense, { Client } from 'typesense';
import { ConfigService } from './config.service';
import { NotificationClient, NotificationSettingsDto } from 'functions/src/models/shared/notificationSettings.model';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  _typesenseClient: Client;
  async getTypesenseClient(): Promise<Client> {

    if (!this._typesenseClient) {
      // Load the object here, e.g. from an API call
      const adminConfig = await this.configService.getAdminConfig().toPromise();
      this._typesenseClient = new Typesense.Client({
        'nodes': [{
          'host': adminConfig.typesense.host,
          'port': 443,
          'protocol': 'https'
        }],
        'apiKey': adminConfig.typesense.usersSearch.apiKey,
        'connectionTimeoutSeconds': 30
      })
    }

    return this._typesenseClient;
  }

  searchPrefix = {
    clientId: "cI_",
    role: "r_",
    clientIdRole: "cIr_"
  }

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private configService: ConfigService
  ) {
    this.init();
  }
  async init() {

  }

  generateUID(): string {
    return this.db.createId();
  }

  getUsers(filters: any): Observable<PaginatedResult<SsoUser>> {
    const query = this.db.collection('users',
      ref => {

        //let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        let query: any = ref;
        if (filters.emailVerifiedFilter !== null) {
          query = query.where("emailVerified", "==", filters.emailVerifiedFilter);

        }
        else if (filters.firstnameFilter !== null) {
          const startsWith: string = filters.firstnameFilter;
          //starts with
          query = query
            .orderBy("firstname")
            .where("firstname", ">=", startsWith)
            .where("firstname", "<", startsWith + 'z')
        }
        else if (filters.lastnameFilter !== null) {
          const startsWith: string = filters.lastnameFilter;
          //starts with
          query = query
            .orderBy("lastname")
            .where("lastname", ">=", startsWith)
            .where("lastname", "<", startsWith + 'z')
        }
        else if (filters.emailFilter !== null) {
          const email: string = filters.emailFilter;
          //starts with
          query = query
            .where("email", "==", email);
        }
        else {
          query = query.orderBy("email");
        }

        //query = query.limit(25);

        return query;
      }
    );

    return query.get().pipe(
      map(results => {
        const paginatedResult = {
          data: convertSnaps<SsoUser>(results),
          lastItem: results.docs[results.docs.length - 1]
        } as PaginatedResult<SsoUser>;

        return paginatedResult;
      })
    )
  }

  searchUsers(criteria: SearchUserCriteria): Observable<PaginatedResult<SsoUser>> {
    const query = this.db.collection('users',
      ref => {
        let query: any = ref;

        if (criteria.searchTerm ||
          criteria.clientId ||
          criteria.roleName) {
          let searchToken: string;
          if (criteria.clientId && criteria.roleName) {
            searchToken = this.formatSearchToken(criteria.searchTerm, "", "", `${criteria.clientId}_${criteria.roleName}`)
          } else {
            searchToken = this.formatSearchToken(criteria.searchTerm, criteria.clientId, criteria.roleName, "")
          }

          query = query.where("searchTokens", "array-contains", searchToken);
        }

        if (criteria.emailVerified) {
          query = query.where("emailVerified", "==", criteria.emailVerified == "true")
        }

        if (criteria.disabled) {
          query = query.where("disabled", "==", criteria.disabled == "true")
        }

        if (criteria.isSsoAdmin) {
          query = query.where("isSsoAdmin", "==", criteria.isSsoAdmin == "true")
        }

        if (criteria.sortExpresssion) {
          const sortTokens = criteria.sortExpresssion.split(".");
          query = query.orderBy(sortTokens[0], sortTokens[1]);
        }

        if (criteria.pagination.lastItem) {
          query = query.startAfter(criteria.pagination.lastItem);
        }

        query = query.limit(criteria.pagination.pageSize);

        return query;
      }
    );

    return query.get().pipe(
      map(results => {
        const paginatedResult = {
          data: convertSnaps<SsoUser>(results),
          lastItem: results.docs[results.docs.length - 1],
          lastPage: criteria.pagination.pageSize > results.docs.length
        } as PaginatedResult<SsoUser>;

        return paginatedResult;
      })
    )
  }

  public async searchUsersTypesense(criteria: SearchUserCriteria) {
    const adminConfig = await this.configService.getAdminConfig().toPromise();
    let search = {
      q: criteria.searchTerm,
      query_by: 'email,firstname,lastname,_id',
      sort_by: criteria.sortExpresssion,
      per_page: criteria.pagination.pageSize,
      page: criteria.pagination.currentPage + 1
    }

    const filter_by = this.composeFilterByClause(criteria);
    if (filter_by) {
      search["filter_by"] = filter_by;
    }
    const typesenseClient = await this.getTypesenseClient();
    let searchResults = await typesenseClient.collections(adminConfig.typesense.usersSearch.collectionName)
      .documents()
      .search(search);

    return searchResults;
  }

  private composeFilterByClause(criteria: SearchUserCriteria): string {
    let filterBy = "";

    if (criteria.isSsoAdmin !== "") {
      filterBy = this.appendFilterByCondition(filterBy, "isSsoAdmin:=" + criteria.isSsoAdmin);
    }

    if (criteria.disabled !== "") {
      filterBy = this.appendFilterByCondition(filterBy, "disabled:=" + criteria.disabled);
    }

    if (criteria.emailVerified !== "") {
      filterBy = this.appendFilterByCondition(filterBy, "emailVerified:=" + criteria.emailVerified);
    }

    if (criteria.clientId && criteria.roleName) {
      filterBy = this.appendFilterByCondition(filterBy, "typesenseSearchTokens:=" + this.searchPrefix.clientIdRole + criteria.clientId + "_" + criteria.roleName);
    }
    else if (criteria.clientId) {
      filterBy = this.appendFilterByCondition(filterBy, "typesenseSearchTokens:=" + this.searchPrefix.clientId + criteria.clientId);
    }
    else if (criteria.roleName) {
      filterBy = this.appendFilterByCondition(filterBy, "typesenseSearchTokens:=" + this.searchPrefix.role + criteria.roleName);
    }

    return filterBy;
  }

  appendFilterByCondition(filterBy: string, condition: string): string {
    if (filterBy !== "") {
      filterBy += " && ";
    }

    filterBy += condition;

    return filterBy;
  }


  private formatSearchToken(
    freeTextToken: string,
    clientToken: string,
    roleToken: string,
    clientRoleToken: string): string {

    return `${freeTextToken} ${clientToken ? this.searchPrefix.clientId + clientToken : ""} ${roleToken ? this.searchPrefix.role + roleToken : ""} ${clientRoleToken ? this.searchPrefix.clientIdRole + clientRoleToken : ""}`.toLowerCase();
  }

  getUserById(id: string): Observable<SsoUser> {
    return this.db.collection("users")
      .doc<SsoUser>(id)
      .get()
      .pipe(
        map(snap => {
          const mappedUser = convertSnap<SsoUser>(snap);
          mappedUser.id = snap.id;

          return mappedUser;
        })
      )
  }

  getUserByEmail(email: string): Observable<SsoUser | null> {
    email = email.toLowerCase();

    return this.db.collection("users", ref => ref.where("email", "==", email))
      .get()
      .pipe(
        map(snaps => {
          let user: SsoUser | null = null;
          if (snaps.docs.length == 1) {
            const mappedUser = convertSnap<SsoUser>(snaps.docs[0]);
            mappedUser.id = snaps.docs[0].id;
            user = mappedUser;
          }

          return user;
        })
      )
  }

  async updateUser(user: SsoUser) {
    user.email = user.email.toLowerCase();
    await this.db.doc('users/' + user.id).update(user);
  }

  async createUser(user: SsoUser) {
    if (user.emailVerified) {
      this.setEmailVerifiedAt(user);
    }
    if (!user.createdAt) {
      user.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    }

    user.email = user.email.toLowerCase();

    await this.db.doc('users/' + user.id).set(user);
  }

  async deleteUser(user: SsoUser) {
    return await this.db.doc('users/' + user.id).delete();
  }

  createAuthUser(user: any) {
    return this.http.post(formatApiUrl('/api/v1/admin/createAuthUser'), user)
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  deleteAuthUser(user: SsoUser) {
    return this.http.post(formatApiUrl('/api/v1/admin/deleteAuthUser'), user)
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  setEmailVerifiedAt(dbUser: SsoUser) {
    dbUser.emailVerifiedAt = firebase.firestore.FieldValue.serverTimestamp()
  }

  sendPasswordResetEmail(userId: string, clientId: string): Observable<SsoApiResponse<SimpleMessage>> {
    return this.http.post<SsoApiResponse<SimpleMessage>>(formatApiUrl('/api/v1/admin/sendPasswordResetEmail'), { userId, clientId })
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  sendPasswordResetEmailFromLogin(email: string, clientId: string): Observable<SsoApiResponse<SimpleMessage>> {
    return this.http.post<SsoApiResponse<SimpleMessage>>(formatApiUrl('/api/v1/profile/passwordReset'), { email, clientId })
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  sendVerifyEmail(userId: string, clientId: string): Observable<SsoApiResponse<SimpleMessage>> {
    return this.http.post<SsoApiResponse<SimpleMessage>>(formatApiUrl('/api/v1/admin/sendVerifyEmail'), { userId, clientId })
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  sendVerifyEmailProfile(userId: string, clientId: string): Observable<SsoApiResponse<SimpleMessage>> {
    return this.http.post<SsoApiResponse<SimpleMessage>>(formatApiUrl('/api/v1/profile/sendVerifyEmail'), { userId, clientId })
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  changePassword(userId: string, newPassword: string): Observable<SsoApiResponse<SimpleMessage>> {
    return this.http.post<SsoApiResponse<SimpleMessage>>(formatApiUrl('/api/v1/admin/setPassword'), { userId, newPassword })
      .pipe(
        catchError(this.logErrorAndThrow)
      )
  }

  async isEmailFree(newEmail: string): Promise<boolean> {
    let isEmailFree = false;

    var existingUser = await this.getUserByEmail(newEmail).toPromise();
    if (existingUser) {
      isEmailFree = false;
    } else {
      isEmailFree = true
    }

    return isEmailFree;
  }

  getUserProfile(): Observable<SsoApiResponse<UserProfile>> {
    return this.http.get<SsoApiResponse<UserProfile>>(formatApiUrl('/api/v1/profile'));
  }

  updateUserProfileNames(firstname: string, lastname: string): Observable<SsoApiResponse<UserProfile>> {
    const request = {
      firstname,
      lastname
    }

    return this.http.post<SsoApiResponse<UserProfile>>(formatApiUrl('/api/v1/profile/names'), request);
  }

  updateUserProfilePassword(email: string, currentPassword: string, newPassword: string): Observable<SsoApiResponse<UserProfile>> {
    const request = {
      currentPassword,
      newPassword
    }

    return this.http.post<SsoApiResponse<UserProfile>>(formatApiUrl('/api/v1/profile/password'), request)
      .pipe(
        tap(response => {
          this.afAuth.signInWithEmailAndPassword(email, newPassword)
        })
      );
  }

  updateUserProfileEmail(email: string, clientId: string): Observable<SsoApiResponse<UserProfile>> {
    const request = {
      email,
      clientId
    }

    return this.http.post<SsoApiResponse<UserProfile>>(formatApiUrl('/api/v1/profile/email'), request);
  }

  verifyEmail(token: string): Observable<SsoApiResponse<UserApp[]>> {

    return this.http.get<SsoApiResponse<UserApp[]>>(formatApiUrl('/api/v1/profile/verifyEmail?token=' + token));
  }

  getApps(statusFilter: string): Observable<SsoApiResponse<UserApp[]>> {
    let url = '/api/v1/profile/apps';
    if(statusFilter){
      url += `?status=${statusFilter}`;
    }

    return this.http.get<SsoApiResponse<UserApp[]>>(formatApiUrl(url));
  }

  createSessionCookie(clientId: string) {
    return this.http.post<SsoApiResponse<any>>(formatApiUrl('/api/v1/sso/login'), { clientId });
  }

  checkSession() {
    return this.http.get<SsoApiResponse<any>>(formatApiUrl('/api/v1/sso/me'), {
      withCredentials: true
    });
  }

  deleteSessionCookie() {
    return this.http.post<SsoApiResponse<any>>(formatApiUrl('/api/v1/sso/logout'), {}, {
      withCredentials: true
    });
  }

  private logErrorAndThrow(err: any) {
    console.log(err);

    return throwError(err);
  }

  getNotificationSettings(): Observable<SsoApiResponse<NotificationSettingsDto>> {
    return this.http.get<SsoApiResponse<NotificationSettingsDto>>(formatApiUrl('/api/v1/profile/notificationSettings'));
  }

  saveNotificationSettings(settings: { notificationsEnabled: boolean, clients: Omit<NotificationClient, 'name' | 'imageURL' | 'isActive' | 'isEmailTemplatesv2Enabled'>[]}) {
    return this.http.post(formatApiUrl('/api/v1/profile/notificationSettings'), { ...settings });
  }
}
