import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, idTokenResult } from '@angular/fire/compat/auth-guard';
import { TopLevelStructureComponent } from './top-level-structure/top-level-structure.component';
import { TopLevelLayoutComponent } from './top-level-layout/top-level-layout.component';
import { ChangePasswordComponent } from './login-widget/change-password/change-password.component';
import { LoginComponent } from './login-widget/login/login.component';
import { ForgotPasswordComponent } from './login-widget/forgot-password/forgot-password.component';
import { ErrorComponent } from './error/error.component';
import { map, pipe } from 'rxjs';
import { CurrentClientService } from './services/current.client.service';
import { VerifyEmailComponent } from './login-widget/verify-email/verify-email.component';

let currentClientService: CurrentClientService;
const redirectUnauthorizedToLoginAuthPipe = () => pipe(idTokenResult, map(idTokenResult => {
  if(!idTokenResult){
    return currentClientService.routePrefix + "/login";
  }
  else{
    return true;
  }  
}));

const dispatchAuthPipe = () => pipe(idTokenResult, map(idTokenResult => {
  const isSsoAdmin = idTokenResult.claims["isSsoAdmin"];
  let relativePath:string;
  
  if(currentClientService.initialRoute){
    relativePath = currentClientService.initialRoute;
    currentClientService.clearInitialRoute();
  }
  else if(isSsoAdmin){
    relativePath = currentClientService.routePrefix + "/admin/user-management";
  }
  else{
    relativePath = currentClientService.routePrefix + "/user/my-apps"
  }

  return relativePath;
}));

const adminOnlyAuthPipe = () => pipe(idTokenResult, map(idTokenResult => {
  const isSsoAdmin = idTokenResult.claims["isSsoAdmin"];

  if (isSsoAdmin) {
    return true;
  } else {
    return currentClientService.routePrefix;
  }
}));


const routes: Routes = [
  { path: '', 
    redirectTo: 'app', 
    pathMatch: 'full'
  },
  {
    path: 'app/:appId',
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLoginAuthPipe
    },
    component: TopLevelStructureComponent,
    children: [
      {
        path: '',
        component: TopLevelLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'dispatch'
          },
          {
            path: 'user',
            loadChildren: () => import('./user/user.module').then(module => module.UserModule),
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule),
            canActivate: [AngularFireAuthGuard],
            data: {
              authGuardPipe: adminOnlyAuthPipe
            }
          },
          {
            path: 'dispatch',
            canActivate: [AngularFireAuthGuard],
            data: {
              authGuardPipe: dispatchAuthPipe
            },
            children:[]
          }
        ]
      }
    ]
  },
  {
    path: 'app/:appId/login',
    component: LoginComponent
  },
  {
    path: 'app/:appId/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'app/:appId/change-password/:id',
    component: ChangePasswordComponent,
  },
  {
    path: 'app/:appId/verify-email/:token',
    component: VerifyEmailComponent,
  },
  {
    path: "**",
    component: ErrorComponent,
    data: { errorPageShown: true },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    public cCS: CurrentClientService
  )
  {
    currentClientService = cCS;
  }
 }
