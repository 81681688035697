import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, take } from 'rxjs';
import { BasicModalComponent } from '../components/modal/basic-modal/basic-modal.component';

@Injectable()

export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) { }

  dialogRef: MatDialogRef<BasicModalComponent>;

  // TODO update options types
  public open(options: any) {
    this.dialogRef = this.dialog.open(BasicModalComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        withInputConfirm: options.withInputConfirm,
        changes: options.changes,
      },
      disableClose: options.disableClose,
      minWidth: options.minWidth || 'auto',
      autoFocus: false
    });
  }
  
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }
}