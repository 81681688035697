import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CurrentClientService } from 'src/app/services/current.client.service';

@Component({
  selector: 'app-sidebar-second',
  templateUrl: './sidebar-second.component.html',
  styleUrls: ['./sidebar-second.component.scss']
})
export class SidebarSecondComponent implements OnInit {

  constructor(
    public currentUserService: CurrentUserService,
    public currentClientService: CurrentClientService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.currentUserService.logout()
  }

}
