import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentClientService } from "../services/current.client.service";
import { LayoutService } from "../shared/services/layout.service";
import { TabTitleService } from "../shared/services/tab-title.service";
import { ThemeService } from "../shared/services/theme.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
  @Input() errorStatus: number;
  @Input() supportEmail: string;

  layoutConfiguration: any;
  themes: any[] = [];
  
  constructor(
    private router: Router,
    public currentClientService: CurrentClientService,
    private layoutService: LayoutService,
    private themeService: ThemeService,
    private tabTitleService: TabTitleService
  ) {}
  mailto(emailAddress: string, emailSubject: any) {
    return "mailto:" + emailAddress + "?subject=" + emailSubject;
  }

  ngOnInit() {
    this.currentClientService.clearInitialRoute();
    this.layoutConfiguration = this.layoutService.layoutConfiguration;
    this.themes = this.themeService.themes;
    //   TODO: Remove once the API with forbidden status gets developed.
    //   This is set only temporarily, for testing purposes.
    this.tabTitleService.setTabTitle('Oops!');
    if (this.router.url.split("/").pop() == "403") {
      this.errorStatus = 403;
      this.supportEmail = "exampleemail@email.com";
    }
  }
}
